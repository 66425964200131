@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda+SC:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');

@keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .animated-background {
    background: linear-gradient(270deg, #06b6d4, #0ea5e9, #14b8a6, #0ea5e9, #06b6d4);
    background-size: 1000% 1000%;
    animation: gradientAnimation 15s ease infinite;
  }