/* Existing styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #277a95;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Apply the fade-in animation */
.animate-fadeIn {
  animation: fadeIn 1.5s ease-out forwards; /* Adjust duration and easing as needed */
}

/* Custom styles */
.custom-name-style {
  font-family: "Cormorant", serif;

  letter-spacing: 0.3em; /* 增加字母间距 */
  text-transform: uppercase; /* 全部大写 */
  font-size: 3.5rem; /* 默认字体大小 */
  display: inline; /* 确保名字在一行 */
}

.initial {
  font-size: 5rem; /* 增大首字母 S 和 G 的字号 */
  font-family: "Cormorant", serif;

}
